<template>
  <div class="tabs_list" :style="setStyleAttribut">
    <ul class="ul_tabs">
      <li
        v-for="(tab, i) in tabs"
        :key="i"
        :class="[tab.to === currentTab ? 'active' : 'inactive']"
        @click="changeTab(tab.to)"
      >
        {{ showLimitedString(tab.title, widthLimitString) }}
      </li>
    </ul>
    <slot></slot>
  </div>
</template>


<script>
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    customs: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    ...mapState(["events"]),
    widthLimitString() {
      if (this.events.width <= 576) {
        return 5;
      } else if (this.events.width <= 768) {
        return 8;
      } else if (this.events.width <= 992) {
        return 15;
      } else {
        return 30;
      }
    },
    setStyleAttribut() {
      const data = {
        "--active-bgcolor": this.customs.activeBgColor || "#333",
        "--active-color": this.customs.activeColor || "#ffc107",
        "--inactive-bgcolor": this.customs.inactiveBgColor || "#111",
        "--inactive-color": this.customs.inactiveColor || "yellow",
        "--border-color": this.customs.borderColor || "#000",
        "--hover-color": this.customs.hoverColor || "#555",
        "--selector-color": this.customs.selectorColor || "#ffc107",
      };
      return data;
    },
    currentTab() {
      return this.$route.params.tab;
    },
  },
  methods: {
    changeTab(tab) {
      if (
        this.$route.params.hasOwnProperty("tab") &&
        this.$route.params.tab !== tab
      ) {
        this.$router.push({ path: tab });
      }
    },
    showLimitedString(string = "", limit = 1) {
      if (limit >= string.length) {
        limit = string.length;
      }
      let limitation = limit < string.length;

      let text = "";
      for (let i = 0; i < limit; i++) {
        text += i === 0 ? string[i].toUpperCase() : string[i];
      }
      return text + (limitation ? "..." : "");
    },
  },
};
</script>

<style scoped>
:root {
  --active-bgcolor: #163a58;
  --active-color: white;
  --inactive-bgcolor: #0c2030;
  --inactive-color: white;
  --border-color: #343434;
  --hover-color: #163a58;
  --selector-color: cyan;
}
.tabs_list {
  padding-top: 2em;
  /* white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden; */
}
.ul_tabs {
  list-style-type: none;
  padding: 0;
  display: inline;
}
.ul_tabs li {
  display: inline-block;
  padding: 0.5em 1em;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: 1s;
}
.ul_tabs li:hover {
  cursor: pointer;
  background: var(--hover-color);
  transition: 0.5s;
}
.ul_tabs .inactive {
  background: var(--inactive-bgcolor);
  color: var(--inactive-color);

  border: 1px solid var(--border-color);
  border-top: 0.25em solid var(--border-color);
  border-bottom: 2px solid var(--border-color);
}
.ul_tabs .active {
  background: var(--active-bgcolor);
  color: var(--active-color);
  border: 1px solid transparent;
  border-top: 0.25em solid var(--selector-color);
  border-bottom: 2px solid transparent;
}
</style>
